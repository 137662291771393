import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TileSliderDots from '../TileSliderDots.js/TileSliderDots';

import './TileSlider.scss';
import 'pepjs';

class TileSlider extends Component {
	state = {
		startIndex: 0,		// The index of the tile that appears on the left-hand side of the tile-area
		tilesShown: 0,
		slideTransition: TileSlider.defaultTransition,
		translateX: 0,
		gridGap: TileSlider.defaultGridGap,
	}

	static propTypes = {
		enableDots: PropTypes.bool,
		gridColumns: PropTypes.object,
		items: PropTypes.array,
		updateDotSelected: PropTypes.func,
		marketingDecor: PropTypes.bool,
		brandRow: PropTypes.bool,
	};

	static swipeThreshold = 30;	// Pixels the slider has to be swiped to activate slider

	static defaultTransition = 'transform .5s ease-in-out';	// Transition to use when sliding

	static defaultGridGap = 12;

	static defaultProps = {
		gridColumns: TileSlider.defaultGridColumns,
	}

	// This should match the media breakpoints in css
	static breakpoints = {
		xs: 0,
		sm: 576,
		mm: 750,
		md: 992,
		lg: 1200,
		xm: 1400,
		xl: 1640
	}

	static defaultGridColumns = {
		xs: 3,
		sm: 4,
		md: 4,
		lg: 5,
		xl: 6
	};

	// Grid gap between tiles. In pixels
	static gridGaps = {
		xs: 6,
		sm: 6,
		md: 12,
		lg: 16,
		xl: 16
	};

	static rowGaps = {
		xs: 8,
		sm: 12,
		mm: 12,
		md: 16,
		lg: 16,
		xm: 16,
		xl: 16
	};

	isDragging = false;
	touchStartX = null;
	touchMoveX = 0;
	mqlListeners = [];
	rotator = null;

	constructor(props) {
		super(props);
		this.sliderRef = React.createRef();
	}

	componentDidMount() {
		// Set-up media queries and breakpoint listeners
		const breakpointKeys = Object.keys(this.props.gridColumns);
		this.setState({
			translateX: 0,
		});
		breakpointKeys.forEach((breakpoint, i) => {
			var mediaString = `(min-width: ${TileSlider.breakpoints[breakpoint]}px)`;

			if (TileSlider.breakpoints[breakpointKeys[i + 1]]) {
				mediaString += ` and (max-width: ${TileSlider.breakpoints[breakpointKeys[i + 1]] - 1}px)`
			}
			const mql = window.matchMedia(mediaString);
			const tilesShown = this.props.gridColumns[breakpoint];
			const gridGap = this.props.brandRow ? TileSlider.rowGaps[breakpoint] : TileSlider.gridGaps[breakpoint];

			if (mql.matches) {
				this.setState({
					tilesShown: tilesShown,
					gridGap: gridGap,
				});
			}

			const mqlHandler = (event) => {
				if (event.matches) {
					const startIndex = this.state.startIndex;
					this.setState({
						tilesShown: tilesShown,
						startIndex: (startIndex >= this.props.items.length - tilesShown) ? this.props.items.length - tilesShown : startIndex,
						slideTransition: 'none',
						gridGap: gridGap,
					})
				}
			}

			mql.addListener(mqlHandler);

			// Store all mql listener references so they can be removed on unmount
			this.mqlListeners.push({ mql, mqlHandler, mediaString });
		});

		if (this.props.enableDots) {
			this.rotator = setInterval(() => this.updateRotator(), 6000);
		}
	}

	updateRotator = () => {
		// check the items length and iterate every item
		let indexCheck = (this.props.items.length - 1) > this.state.startIndex;
		this.setState({
			startIndex: (indexCheck) ? this.state.startIndex + 1 : 0,
			slideTransition: (indexCheck) ? 'transform 1.5s ease-out' : 'transform 1.0s ease-out',
			translateX: this.calculateTranslateX((indexCheck) ? this.state.startIndex + 1 : 0, 0),
		});
		this.props.updateDotSelected((indexCheck) ? this.state.startIndex + 1 : 0);   // for GSM-4372 Updating the state value is delayed in React v18, hence (this.state.startIndex + 1)
	};

	componentWillUnmount() {
		// remove all media query listeners otherwise React will emit an error message
		this.mqlListeners.forEach((item) => {
			item.mql.removeListener(item.mqlHandler);
		});
		if (this.props.enableDots) {
			clearInterval(this.rotator);
		}
	}

	previousTiles = () => {
		const tilesShown = this.state.tilesShown;
		let newStartIndex = 0;
		if (this.state.startIndex - tilesShown > 0) {
			newStartIndex = this.state.startIndex - tilesShown;
		}
		this.setState({
			startIndex: newStartIndex,
			slideTransition: TileSlider.defaultTransition,
			translateX: this.calculateTranslateX(newStartIndex, 0),
		});
		if (this.props.enableDots) {
			this.props.updateDotSelected(newStartIndex);
		}
	}

	nextTiles = () => {
		const tilesShown = this.state.tilesShown;
		let newStartIndex = 0;
		if (this.props.items.length > tilesShown) { // Only move if there are enough tiles
			newStartIndex = Math.min(this.state.startIndex + tilesShown, this.props.items.length - tilesShown);
			this.setState({
				startIndex: newStartIndex,
				slideTransition: TileSlider.defaultTransition,
				translateX: this.calculateTranslateX(newStartIndex, 0),
			});
		}
		if (this.props.enableDots) {
			this.props.updateDotSelected(newStartIndex);
		}
	}

	calculateTranslateX = (newStartIndex, moveX) => {
		const tilesShown = this.state.tilesShown;
		const sliderWidth = this.sliderRef.current.clientWidth;
		return -(newStartIndex / tilesShown * sliderWidth) - (newStartIndex / tilesShown * this.state.gridGap) - moveX;
	}

	onTileClick = (event) => {
		// Don't propagate if the tile was dragged or swiped
		if (this.touchStartX && Math.abs(this.touchStartX - event.pageX) > TileSlider.swipeThreshold) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	onDown = (event) => {
		if (event.button === 2) {	// Ignore right-clicks
			return;
		}
		this.isDragging = true;
		event.target.setPointerCapture(event.pointerId);
		event.target.setAttribute('draggable', false);
		this.touchStartX = event.pageX;
	}

	onMove = (event) => {
		// Only move if being dragged
		if (!this.isDragging) {
			return;
		}

		const startIndex = this.state.startIndex;
		const tilesShown = this.state.tilesShown;

		this.touchMoveX = event.pageX;
		const moveX = this.touchStartX - this.touchMoveX;

		// Don't move to the right if at left end of tiles
		if (moveX < 0 && startIndex === 0) {
			return;
		}

		// Don't move to the left if at right end of tiles
		if (moveX > 0 && startIndex >= this.props.items.length - tilesShown) {
			return;
		}

		// Drag the slider
		this.setState({
			slideTransition: 'none',
			translateX: this.calculateTranslateX(startIndex, moveX),
		})
	}

	onUp = (event) => {
		if (event.button === 2) {	// Ignore right-clicks
			return;
		}
		this.isDragging = false;
		const deltaX = event.pageX - this.touchStartX;

		// Dragged far enough to slide tiles
		if (deltaX < -(TileSlider.swipeThreshold)) {
			this.nextTiles();
		} else if (deltaX > TileSlider.swipeThreshold) {
			this.previousTiles();
		} else {
			// Reset slider position
			this.setState({
				translateX: this.calculateTranslateX(this.state.startIndex, 0)
			})
		}

		event.target.releasePointerCapture(event.pointerId);
	}

	setSelectedSlide = (slideIndex, item) => {
		//clear the existing interval and initiate the new one bcz it will restart again,
		clearInterval(this.rotator);
		this.rotator = setInterval(() => this.updateRotator(), 6000);
		this.setState({
			startIndex: slideIndex,
			slideTransition: 'transform 0.75s ease-out',
			translateX: this.calculateTranslateX(slideIndex, 0),
		});

		if (this.props.enableDots) {
			this.props.updateDotSelected(slideIndex, item);
		}
	}

	resetSlider = () => {
		this.setState({
			startIndex: 0,
			translateX: 0,
		})
	}

	render() {
		const { startIndex, tilesShown, slideTransition } = this.state;
		const gridGap = this.state.gridGap;
		let tiles = this.props.items.map((item, index) => {

			let className = 'TileSlide';
			if (this.props.enableDots) {
				className += ' TilePosition'
			}
			if (index >= startIndex && index < startIndex + tilesShown) {
				className += ' TileSlide--active';
			}
			if (
				item.props?.data
				&& item.props.data.subtype
				&& (item.props.data.subtype === 'anchor' || item.props.data.subtype === 'survey')) {
				className += ' link-out';
			}

			/**
			 * Check if slider has marketing decorator text. If so, that will make slider item hight larger than regular image height
			 * to style the view more tile, we need to know if that slider has marketingDecor.
			 */
			if (this.props.marketingDecor) {
				className += ' with-marketing-decor';
			}
			return (
				<div
					key={index}
					className={className}
					onClickCapture={(event) => { this.onTileClick(event, index) }}
				>{item}</div>
			)
		})

		if (this.props.enableDots) {
			var dots = [];
			dots = this.props.items.map((item, index) => {
				/* generate slider dots */
				let activeSliderClass = '';
				if (index >= startIndex && index < startIndex + tilesShown) {
					activeSliderClass = 'active';
				}

				return (
					<TileSliderDots key={index} activeSliderClass={activeSliderClass} setSlide={() => this.setSelectedSlide(index, item)} />
				)
			});
		}

		const tileSliderStyle = {
			display: 'grid',
			gridAutoFlow: 'column',
			gridGap: `${gridGap}px`,
			gridAutoColumns: `calc((100% - ${gridGap}px * (${tilesShown} - 1)) / ${tilesShown})`,
			transform: `translateX(${(this.props.enableDots) ? 0 : this.state.translateX}px)`,
			transition: `${(this.props.enableDots) ? 'opacity 1.5s ease-in' : slideTransition}`,
		};

		return (
			<div className="TileSlider" ref={this.sliderRef}>
				<button
					className="TileSlider-navigate TileSlider-navigate--prev"
					onClick={() => this.previousTiles()}
					disabled={tiles.length <= tilesShown || startIndex === 0}
				>Previous</button>
				<div
					className="TileSlider-items"
					style={tileSliderStyle}
					onPointerDown={this.onDown}
					onPointerMove={this.onMove}
					onPointerUp={this.onUp}
				>{tiles}</div>
				<button
					className="TileSlider-navigate TileSlider-navigate--next"
					onClick={() => this.nextTiles()}
					disabled={tiles.length <= tilesShown || startIndex === tiles.length - tilesShown}
				>Next</button>
				<div className="TileSlider-dots-wrapper">
					{dots}
				</div>
			</div>
		)
	}
}

export default TileSlider;
