import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './VideoTileArea.scss';
import { VideoTile } from '../../tiles';
import fromVms from '../../../utility/fromVms';
import TileSlider from '../../slider/TileSlider/TileSlider';
import { getCookie } from '../../../../utils/helpers';

class VideoTileArea extends Component {
	static propTypes = {
		brands: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string,
		]),
		count: PropTypes.number,
		order: PropTypes.number,
		expanded: PropTypes.bool,
		guid: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string,
		]),
		items: PropTypes.array,
		maxNewItems: PropTypes.number,
		showIsNew: PropTypes.bool,
		loading: PropTypes.bool,
		itemsProps: PropTypes.bool,
		ordering: PropTypes.string,
		gridColumns: PropTypes.object,
		collectionId: PropTypes.string,
		seasonId: PropTypes.string,
		showId: PropTypes.string,
		parentId: PropTypes.string,
		title: PropTypes.string,
		closeIcon: PropTypes.func,
		onNow: PropTypes.object,
		behaviour: PropTypes.string,
		enlarged_row: PropTypes.string,
		type: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string,
		]),
	};

	static defaultProps = {
		expanded: false,
	}

	constructor(props) {
		super(props);
		this.tileSliderRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if (this.props.collectionId !== prevProps.collectionId || this.props.seasonId !== prevProps.seasonId) { // If a new season was selected
			if (this.tileSliderRef.current) {
				this.tileSliderRef.current.resetSlider();
			}
		}
	}

	render() {
		if (this.props?.title === 'Favourite Channels') {
			let channelLength = this.props?.items?.length
			if (channelLength % 4 === 0) {
				this.tileSliderRef?.current?.resetSlider();
			}
		}
		// If finished loading and no items were found, don't render anything.
		if (this.props.title !== 'Continue Watching' && (!this.props.loading && (!this.props.items || !this.props.items.length) || (!this.props.itemsProps && (!this.props.collectionId && !this.props.seasonId)))) {
			return <div className={'Empty-data'}>Sorry, there are no videos available right now, please try again later.</div>;
		}

		const gridColumns = this.props?.enlarged_row ? {
			xs: 2,
			md: 3,
		}
			: {
				xs: 3,
				md: 4,
			};

		let startIndex = 0;

		let { expanded, items, title, type, maxNewItems, guid, behaviour } = this.props;
		let newItemsShown = 0;

		// Check whether we have a valid cookie with value of last streamed global news station to perform a sort
		if (type === 'channel' && getCookie('lastNewsStream') !== '' && items.find(item => item.origin === 'globalnewsott' && this.props.title !== 'Favourite Channels')) {
			// Find last streamed station based on its guid and move it to the first position in array
			items.map((item, index) => {
				if (item.guid === getCookie('lastNewsStream')) {
					items.unshift(items.splice(index, 1)[0]);
				}
			})
		}

		// Find which item is the closest to 
		if (items && items.length > 0) {
			const first_item = new Date(items[0].data.reference_date || items[0].data.pub_date).getTime();
			const last_item = new Date(items[items.length - 1].data.reference_date || items[items.length - 1].data.pub_date).getTime()
			if (first_item >= last_item) {
				startIndex = 0;
			} else {
				startIndex = items.length - 1;
			}

			for (let i = startIndex; startIndex === 0 ? i < items.length : i >= 0; startIndex === 0 ? i++ : i--) {
				const itemData = items[i];
				const days_since = parseInt((new Date().getTime() - new Date(itemData.data.reference_date || itemData.data.pub_date).getTime()) / (24 * 3600 * 1000))
				const showNewItem = days_since <= 7 && itemData.type === 'episode' && newItemsShown < maxNewItems;
				if (showNewItem) {
					newItemsShown++;
				}

				items[i] = {
					...itemData,
					showNewItem
				}
			}
		}

		// Reorder continue watching based on GUID
		if (title === 'Continue Watching') {
			let continue_watching_order = [];

			for (let i = guid.length; i >= 0; i--) {
				const g = guid[i];
				let item_to_add = items.find((item) => item.guid === g);
				if (item_to_add) {
					continue_watching_order.unshift(item_to_add);
				}
			}

			items = continue_watching_order;
		}
		const tiles = items.map((itemData, index) => {
			return (
				<VideoTile
					key={itemData.guid}
					expanded={expanded}
					{...itemData}
					items={items}
					parentId={this.props.parentId}
					ordering={this.props.ordering}
					order={this.props.order}
					title={this.props.title}
					tilePosition={index}
					showIsNew={itemData.showNewItem}
					onNow={this.props?.onNow || null}
					behaviour={behaviour}
				/>
			)
		})

		const header = () => {
			if (this.props.title === 'Continue Watching') {
				localStorage.setItem('CW', true); // Iterate the order for analytics based on the value
			}
			return (
				<div className="header">
					{title && <h3 className="VideoTileArea-title">{title}</h3>}
					{title === 'Continue Watching' &&
						<div>
							{this.props.closeIcon()}
						</div>
					}
				</div>
			)
		}

		return (

			<div className={'VideoTileArea'}>
				{header()}
				<TileSlider
					className="VideoTileArea-items"
					items={tiles}
					gridColumns={gridColumns}
					expanded={expanded}
					ref={this.tileSliderRef}
				>
				</TileSlider>
			</div>
		)
	}
}

const propMapping = ({ guid, collectionId, limit, ordering, parentId, seasonId, showId, type }) => {

	let parent = type === 'channel' ? parentId : collectionId;

	if (collectionId) {
		parent = collectionId;
	}

	if (seasonId) {
		parent = seasonId;
	}

	return {
		ordering: ordering || '-reference_date',
		parent: parent,
		primary_parent: collectionId ? '' : showId,
		type: (!type || type === 'both') ? ['media', 'episode', 'movie'] : type,
		guid: guid,
		limit: limit,
	}
}

export default fromVms(VideoTileArea, propMapping);
